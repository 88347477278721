<div class="content">
	<router-outlet></router-outlet>
</div>
<div class="corner">
	<a routerLink="/">
		<img src="/assets/img/CumLaude_Logo_white.svg" />
	</a>
</div>
<app-hamburger-menu></app-hamburger-menu>
<app-admin-header></app-admin-header>

import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { RBestuur, RInstelling, RInstellingAdditionalObjectKey } from '@cumlaude/service-contract';
import { firstValueFrom, Observable, switchMap } from 'rxjs';
import { RestService } from '@cumlaude/shared-services';
import { AsyncPipe, DatePipe } from '@angular/common';
import { InstellingLabelsComponent } from '../../components/instelling-labels/instelling-labels.component';
import { ConfirmDialogComponent } from '@cumlaude/shared-components-dialogs';
import { Dialog } from '@angular/cdk/dialog';
import { BestuurService } from '../../services/bestuur.service';
import { ToastrService } from 'ngx-toastr';
import { LicentiesCardComponent } from '../instelling/licenties-card.component';
import { ModulesCardComponent } from '../instelling/modules-card.component';
import { intValueFromInputElement } from '@cumlaude/shared-utils';
import { InstellingenListComponent } from '../../components/instellingen-list/instellingen-list.component';

@Component({
	selector: 'app-bestuur-page',
	templateUrl: './bestuur-page.component.html',
	styleUrl: './bestuur-page.component.scss',
	imports: [
		AsyncPipe,
		ButtonComponent,
		InstellingLabelsComponent,
		ConfirmDialogComponent,
		DatePipe,
		LicentiesCardComponent,
		ModulesCardComponent,
		InstellingenListComponent,
	],
	standalone: true,
})
export class BestuurPageComponent implements OnInit {
	bestuur$!: Observable<RBestuur>;
	instellingen$!: Observable<RInstelling[]>;

	private id!: number;

	@ViewChild('confirmBestuurOpheffen')
	confirmBestuurOpheffen!: TemplateRef<{ data: { naam: string } }>;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private restService: RestService,
		protected dialog: Dialog,
		protected bestuurService: BestuurService,
		protected toastr: ToastrService
	) {}

	ngOnInit(): void {
		const idParam = this.route.snapshot.paramMap.get('id');
		this.id = Number.parseInt(idParam!);
		if (isNaN(this.id)) {
			throw new Error(`Invalid ID: ${idParam}`);
		}

		this.bestuur$ = this.restService.getBestuur(this.id);
		this.instellingen$ = this.bestuur$.pipe(switchMap((bestuur) => this.restService.getInstellingen(bestuur, [RInstellingAdditionalObjectKey.LICENTIES])));
	}

	goBack() {
		this.router.navigate(['bestuur']);
	}

	deleteBestuur(bestuur: RBestuur) {
		const dialogRef = this.dialog.open(this.confirmBestuurOpheffen, {
			data: { naam: bestuur.naam },
		});
		dialogRef.closed.subscribe((result) => {
			if (result) {
				this.bestuurService.removeInstellingenFromBestuur(bestuur, bestuur.instellingen!).then((r) =>
					this.restService.deleteBestuur(bestuur).subscribe(() => {
						this.toastr.success(`Bestuur ${bestuur.naam} is verwijderd.`);
						this.goBack();
					})
				);
			}
		});
	}

	editBestuur() {
		this.router.navigate(['bestuur', this.id, 'edit']);
	}

	async changeLicenties(bestuur: RBestuur, event: Event) {
		const value = intValueFromInputElement(event);
		if (value !== bestuur.licenties) {
			await firstValueFrom(this.restService.putBestuur({ ...bestuur, licenties: value }));
		}
	}

	gotoInstelling(bestuur: RBestuur, instelling: RInstelling) {
		this.router.navigate(['instelling', instelling.id], { queryParams: { bestuur: bestuur.id } });
	}
}

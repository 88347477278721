<app-button buttonType="text" icon="icon-arrow-l" (clickit)="goBack()">Terug naar besturen</app-button>
<div class="header">
	<h2>Nieuw bestuur aanmaken</h2>
</div>
<div class="cards">
	<div class="row">
		<div class="column">
			<div class="card">
				<app-bestuur-settings></app-bestuur-settings>
			</div>
		</div>
	</div>
</div>

import { Component, Input } from '@angular/core';
import { InstellingBron, RInstelling } from '@cumlaude/service-contract';

@Component({
	selector: 'app-instelling-labels',
	templateUrl: './instelling-labels.component.html',
	styleUrl: './instelling-labels.component.scss',
	standalone: true,
})
export class InstellingLabelsComponent {
	@Input()
	instelling!: RInstelling;

	@Input()
	toonBestuur = true;

	InstellingBron = InstellingBron;
}

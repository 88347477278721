import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { AsyncPipe } from '@angular/common';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { TopLevelMenuComponent } from '@cumlaude/shared-components-menu';

@Component({
	selector: 'app-admin-header',
	templateUrl: './admin-header.component.html',
	styleUrls: ['./admin-header.component.scss'],
	standalone: true,
	imports: [TopLevelMenuComponent, NgClickOutsideDirective, RouterLink, AsyncPipe],
})
export class AdminHeaderComponent {}

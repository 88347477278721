<div class="container">
	<div class="input">
		<span class="label">Naam instelling</span>
		<div class="input-field">
			<input #inpNaam (input)="naam.set(inpNaam.value)" [value]="naam()" type="text" />
		</div>
		<span class="sub-text">Let op: deze naam is ook zichtbaar in CumLaude voor de gebruiker.</span>
	</div>
	<div class="sub-container">
		<div>
			<span> Er moet verbinding worden gemaakt met de FTP server van Magister. Hiervoor is een gebruikersnaam en wachtwoord nodig. Voeg deze toe aan de Keyhub-kluis "Cumlaude - Magister FTP" en vul hieronder het UUID van de aangemaakte kluisregel in.</span>
		</div>
		<div class="input">
			<span class="label">Kluisregel UUID</span>
			<div class="input-field">
				<input #inpVaultRecord (input)="vaultRecord.set(inpVaultRecord.value)" [value]="vaultRecord()" type="text" />
			</div>
		</div>
		<div class="buttons">
			@if (nieuw) {
				<app-button buttonType="primary" (clickit)="toevoegen()" class="right" [enabled]="isFilled()">Toevoegen</app-button>
			} @else {
				<app-button buttonType="secondary" (clickit)="annuleren()" class="right">Annuleren</app-button>
				<app-button buttonType="primary" (clickit)="opslaan()" [enabled]="isFilled()">Opslaan</app-button>
			}
		</div>
	</div>
</div>

<div class="header">
	<h2>Besturen</h2>
	<app-button class="right" (clickit)="openBestuurToevoegen()" icon="icon-add">Bestuur aanmaken</app-button>
</div>
<div class="search-header">
	<app-instant-search-box (fire)="searchInput$.next($event)" placeholder="Bestuur of instelling zoeken"></app-instant-search-box>
</div>
<div class="content">
	@if (besturen$ | async; as besturen) {
		<table>
			<thead>
				<tr>
					<th>Bestuur</th>
					<th>Instelling</th>
					<th>Labels</th>
				</tr>
			</thead>
			<tbody>
				@for (bestuur of besturen; track bestuur.id; let odd = $odd; let even = $even) {
					@for (instelling of bestuur.instellingen; track instelling.id; let first = $first) {
						<tr (click)="gotoBestuur(bestuur)" [class.odd]="odd" [class.even]="even">
							<td>
								@if (first) {
									<span>
										{{ bestuur.naam }}
									</span>
								}
							</td>
							<td>
								<span>
									{{ instelling.naam }}
								</span>
							</td>
							<td>
								<app-instelling-labels [instelling]="instelling" [toonBestuur]="false"></app-instelling-labels>
							</td>
						</tr>
					} @empty {
						<tr (click)="gotoBestuur(bestuur)" [class.odd]="odd" [class.even]="even">
							<td>
								<span>
									{{ bestuur.naam }}
								</span>
							</td>
							<td></td>
							<td></td>
						</tr>
					}
				}
			</tbody>
		</table>
	}
</div>

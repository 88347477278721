import { Component, Inject, OnInit } from '@angular/core';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';
import { MagisterInstellingSettingsComponent } from './magister-instelling-settings.component';
import { RMagisterInstelling } from '@cumlaude/service-contract';
import { InstellingLabelsComponent } from '../../components/instelling-labels/instelling-labels.component';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { RestService } from '@cumlaude/shared-services';
import { AsyncPipe } from '@angular/common';

@Component({
	selector: 'app-magister-instelling-bewerken-page',
	templateUrl: './magister-instelling-bewerken-page.component.html',
	styleUrl: './magister-instelling-bewerken-page.component.scss',
	imports: [ButtonComponent, MagisterInstellingSettingsComponent, InstellingLabelsComponent, AsyncPipe],
	standalone: true,
})
export class MagisterInstellingBewerkenPageComponent implements OnInit {
	somtodayUrl: string;

	enviromentName: string;

	instelling$!: Observable<RMagisterInstelling>;

	private id!: number;

	constructor(
		private route: ActivatedRoute,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private restService: RestService
	) {
		this.enviromentName = getFriendlyEnvironmentName(this.envConfig.environmentName);
		this.somtodayUrl = envConfig.somtodayUrl!;
	}

	ngOnInit() {
		const idParam = this.route.snapshot.paramMap.get('id');
		this.id = Number.parseInt(idParam!);
		if (isNaN(this.id)) throw new Error(`Invalid ID: ${idParam}`);

		this.instelling$ = this.restService.getMagisterInstelling(this.id);
	}
}

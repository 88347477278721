<app-button buttonType="text" icon="icon-arrow-l" (clickit)="goBack()">Terug naar {{ enviromentName }}</app-button>
<div class="header">
	<h2>Instelling toevoegen</h2>
</div>

<div class="cards">
	<div class="row">
		<div class="column">
			<div class="card">
				<div class="card-header">
					<span>Magister</span>
					<div class="svg-magister right"></div>
				</div>
				<app-magister-instelling-settings></app-magister-instelling-settings>
			</div>
		</div>
		<div class="column">
			<div class="card">
				<div class="card-header">
					<span>Somtoday</span>
					<div class="svg-somtoday right"></div>
				</div>
				<div>
					Het toevoegen van een Somtoday instelling kan door met het support account in te loggen in de desbetreffende Somtoday omgeving.
					Vervolgens kan in beheer de feature CumLaude aangezet worden. Vanaf dan zal de instelling meegenomen worden in de nachtelijke
					verwerking.
				</div>
				<a class="card-link" [href]="somtodayUrl">
					<span>Naar Somtoday</span>
					<div class="svg-new-page"></div>
				</a>
			</div>
		</div>
	</div>
</div>

<div class="container">
	<div class="input">
		<span class="label">Naam bestuur</span>
		<div class="input-field">
			<input #inpNaam (input)="naam.set(inpNaam.value)" [value]="naam()" type="text" />
		</div>
	</div>
	@if (instellingen$ | async; as instellingen) {
		<div class="search-header">
			<span class="label">Selecteer instellingen</span>
			<div class="search-components">
				<app-instant-search-box (fire)="searchInput.set($event)" placeholder="Instelling zoeken"></app-instant-search-box>
				<app-checkbox
					(valueChange)="toonSelectie.set($event)"
					[value]="toonSelectie()"
					[label]="'Selectie tonen (' + checkedInstellingen().length + ')'"
				/>
			</div>
		</div>
		@if (filterInstellingen(instellingen); as filteredInstellingen) {
			<app-instellingen-list
				[instellingen]="filteredInstellingen"
				[checked]="checkedInstellingen()"
				[disabled]="disabledInstellingen()"
				[statusEnabled]="true"
				(instellingClick)="updateChecked($event)"
			>
			</app-instellingen-list>
		}

		<div class="buttons">
			@if (nieuw) {
				<app-button buttonType="primary" (clickit)="toevoegen(instellingen)" class="right" [enabled]="isFilled()">Aanmaken</app-button>
			} @else {
				<app-button buttonType="secondary" (clickit)="annuleren()" class="right">Annuleren</app-button>
				<app-button buttonType="primary" (clickit)="opslaan(instellingen)" [enabled]="isFilled()">Opslaan</app-button>
			}
		</div>
	}
</div>

import { enableProdMode } from '@angular/core';
import localeNl from '@angular/common/locales/nl';
import { createAppConfig } from './app/app.config';
import { ENV_CONFIG_URL, EnvConfiguration } from '@cumlaude/shared-configuration';
import { enableBugsnag } from '@cumlaude/bugsnag';
import { registerLocaleData } from '@angular/common';
import { bootstrapApplication } from '@angular/platform-browser';
import { AppComponent } from './app/app.component';

fetch(ENV_CONFIG_URL)
	.then((config) => config.json())
	.then((envConfig: EnvConfiguration) => {
		if (envConfig.production) {
			enableProdMode();
		}

		enableBugsnag(envConfig, 'Admin Frontend');

		registerLocaleData(localeNl);

		bootstrapApplication(AppComponent, createAppConfig(envConfig)).catch(console.error);
	});

@if (bestuur$ | async; as bestuur) {
	<div class="header">
		<h2>Bestuur bewerken</h2>
	</div>
	<div class="cards">
		<div class="row">
			<div class="column">
				<div class="card">
					<app-bestuur-settings [bestuur]="bestuur"></app-bestuur-settings>
				</div>
			</div>
		</div>
	</div>
}

import { Component, Inject } from '@angular/core';
import { ButtonComponent } from '@cumlaude/shared-components-buttons';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';
import { HistoryService } from '@cumlaude/shared-services';
import { MagisterInstellingSettingsComponent } from './magister-instelling-settings.component';

@Component({
	selector: 'app-instelling-toevoegen-page',
	templateUrl: './instelling-toevoegen-page.component.html',
	styleUrl: './instelling-toevoegen-page.component.scss',
	imports: [ButtonComponent, MagisterInstellingSettingsComponent],
	standalone: true,
})
export class InstellingToevoegenPageComponent {
	somtodayUrl: string;

	enviromentName: string;

	constructor(
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private historyService: HistoryService
	) {
		this.enviromentName = getFriendlyEnvironmentName(this.envConfig.environmentName);
		this.somtodayUrl = envConfig.somtodayUrl!;
	}

	goBack() {
		this.historyService.back();
	}
}

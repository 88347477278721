<div class="cards">
	@if (instelling.bestuur !== undefined) {
		<div class="card">
			<h3>Module Bestuur</h3>
			<table>
				<tbody>
					<tr>
						<td class="name">{{ instelling.naam }}</td>
						<td>
							<app-checkbox [value]="instelling.bestuurEnabled!" (valueChange)="changeBestuurEnabled(instelling, $event)" />
						</td>
						<td></td>
						<td></td>
					</tr>
				</tbody>
			</table>
		</div>
	}
	<div class="card">
		<h3>Modules per vestiging</h3>
		@if (vestigingen$ | async; as vestigingen) {
			@if (vestigingen.length) {
				<table>
					<thead>
						<tr>
							<th></th>
							<th class="header">School</th>
							@for (module of cumlaudeModules; track $index) {
								<th class="header">{{ module | titlecase }}</th>
							}
						</tr>
					</thead>
					<tbody>
						@for (vestiging of vestigingen; track vestiging.vestigingId) {
							<tr [class.inactief]="!vestiging.actief">
								<td class="name">{{ vestiging.naam }} {{ vestiging.actief ? "" : " (inactief)" }}</td>
								<td>
									<app-checkbox [value]="vestiging.cumlaudeActief" (valueChange)="setCumlaudeActief(vestiging, $event)" />
								</td>
								@for (module of cumlaudeModules; track $index) {
									<td>
										<app-checkbox
											[value]="vestiging.modules.includes(module)"
											(valueChange)="setModuleActief(vestiging, module, $event)"
											[enabled]="vestiging.cumlaudeActief"
										/>
									</td>
								}
							</tr>
						}
					</tbody>
				</table>
			} @else {
				<div>Geen vestigingen gevonden</div>
			}
		}
	</div>
</div>

<h3>Licenties</h3>
<table class="licenties">
	<thead>
		<tr>
			<th></th>
			<th class="header">School</th>
			<th class="header">Sectie</th>
			<th class="header">Docent</th>
			<th class="header">Totaal</th>
		</tr>
	</thead>
	<tbody>
		<tr>
			<td class="name">Beschikbaar</td>
			<td>
				<input
					class="cell-value"
					type="number"
					min="0"
					max="999"
					[value]="instelling.licentiesSchoolbreedTeam"
					[disabled]="!isCumLaudeActief()"
					(blur)="change('licentiesSchoolbreedTeam', $event)"
				/>
			</td>
			<td>
				<input
					class="cell-value"
					type="number"
					min="0"
					max="999"
					[value]="instelling.licentiesSectie"
					[disabled]="!isCumLaudeActief() || !instelling.modules!.includes(RCumLaudeModule.SECTIE)"
					(blur)="change('licentiesSectie', $event)"
				/>
			</td>
			<td>
				<div class="cell-value" [class.disabled]="!isCumLaudeActief() || !instelling.modules!.includes(RCumLaudeModule.DOCENT)">
					{{ licentieInfo.docentMentor?.totaal ?? "" }}
				</div>
			</td>
			<td>
				<div class="cell-value">{{ licentiesTotaal.totaal }}</div>
			</td>
		</tr>
		<tr>
			<td class="name">In gebruik</td>
			<td>
				<div class="cell-value" [class.disabled]="!isCumLaudeActief()">
					{{ licentieInfo.schoolbreedTeam.inGebruik }}
				</div>
			</td>
			<td>
				<div class="cell-value" [class.disabled]="!isCumLaudeActief() || !instelling.modules!.includes(RCumLaudeModule.SECTIE)">
					{{ licentieInfo.sectie?.inGebruik ?? "" }}
				</div>
			</td>
			<td>
				<div class="cell-value" [class.disabled]="!isCumLaudeActief() || !instelling.modules!.includes(RCumLaudeModule.DOCENT)">
					{{ licentieInfo.docentMentor?.inGebruik ?? "" }}
				</div>
			</td>
			<td>
				<div class="cell-value">{{ licentiesTotaal.inGebruik }}</div>
			</td>
		</tr>
	</tbody>
</table>

import { Inject, Injectable, OnDestroy } from '@angular/core';
import { AppAuthenticationService, AuthService, Menu } from '@cumlaude/shared-authentication';
import { Subscription } from 'rxjs';
import { AdminBugsnagService } from '@cumlaude/admin-bugsnag';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';

@Injectable({
	providedIn: 'root',
})
export class AdminAuthenticationService extends AppAuthenticationService implements OnDestroy {
	private subscriptions: Subscription[] = [];

	constructor(
		authService: AuthService,
		adminBugsnagService: AdminBugsnagService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration
	) {
		super();
		this.subscriptions.push(
			authService.loggedIn$.subscribe(() => {
				const userDisplayName = authService.getUserDisplayName();
				adminBugsnagService.setUser(userDisplayName);
			})
		);
	}

	ngOnDestroy() {
		for (const sub of this.subscriptions) sub.unsubscribe();
	}

	clearSessionStorage() {
		//Nog geen Admin specifieke session storage
	}

	getOAuthParams() {
		return { federate: 'keyhub' };
	}

	getMenus(): Menu[] {
		return [
			{
				conditie: (_url: string) => true,
				menus: [
					{ naam: getFriendlyEnvironmentName(this.envConfig.environmentName), url: '/instelling' },
					{ naam: 'Besturen', url: '/bestuur' },
				],
			},
		];
	}

	isUrlAllowed(_url: string): boolean {
		//Voor nu alles toegestaan, later mogelijk specifieke toegang via hier regelen.
		return true;
	}
}

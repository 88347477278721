import { Component, Inject, signal } from '@angular/core';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';
import { ENV_CONFIG, EnvConfiguration, getFriendlyEnvironmentName } from '@cumlaude/shared-configuration';
import { InstellingBron, RInstelling } from '@cumlaude/service-contract';
import { AsyncPipe } from '@angular/common';
import { ButtonComponent, ToggleButtonComponent } from '@cumlaude/shared-components-buttons';
import { FormDropdownComponent, InstantSearchBoxComponent, Option } from '@cumlaude/shared-components-inputs';
import { RestService } from '@cumlaude/shared-services';
import { toObservable } from '@angular/core/rxjs-interop';
import { find, isUndefined } from 'lodash-es';
import { Router } from '@angular/router';
import { InstellingenListComponent } from '../../components/instellingen-list/instellingen-list.component';
import { includesIgnoreCaseAndDiacritics } from '@cumlaude/shared-utils';

@Component({
	selector: 'app-instellingen-page',
	templateUrl: './instellingen-page.component.html',
	styleUrls: ['./instellingen-page.component.scss'],
	standalone: true,
	imports: [AsyncPipe, ToggleButtonComponent, ButtonComponent, InstantSearchBoxComponent, FormDropdownComponent, InstellingenListComponent],
})
export class InstellingenPageComponent {
	instellingen$: Observable<RInstelling[]>;
	labels = [new Option(undefined, '<Alle>'), ...Object.values(InstellingBron).map((label) => new Option<string>(label)), new Option<string>('VSO')];
	searchInput$ = new BehaviorSubject<string>('');

	selectedLabel = signal<string | undefined>(undefined);

	uuidEnabled: boolean = false;
	enviromentName: string;

	constructor(
		private restService: RestService,
		@Inject(ENV_CONFIG) private readonly envConfig: EnvConfiguration,
		private router: Router
	) {
		const instellingenObservable = this.restService.getInstellingen();
		this.instellingen$ = combineLatest([instellingenObservable, this.searchInput$, toObservable(this.selectedLabel)]).pipe(
			map(([instellingen, searchInput, selectedLabel]) => this.filterInstellingen(instellingen, searchInput, selectedLabel))
		);
		this.enviromentName = getFriendlyEnvironmentName(this.envConfig.environmentName);
	}

	private filterInstellingen(instellingen: RInstelling[], searchInput: string, selectedLabel: string | undefined) {
		return instellingen
			.filter((instelling) => {
				return (
					includesIgnoreCaseAndDiacritics(instelling.naam, searchInput) ||
					includesIgnoreCaseAndDiacritics(instelling.bestuur?.naam ?? '', searchInput)
				);
			})
			.filter((instelling) => {
				if (isUndefined(selectedLabel)) return true;

				if (selectedLabel === 'VSO') return instelling.vso;
				else if ((<string[]>Object.values(InstellingBron)).includes(selectedLabel)) return selectedLabel === instelling.bron;
				else return instelling.bestuur && instelling.bestuur.naam === selectedLabel;
			});
	}

	openInstellingToevoegen() {
		this.router.navigate(['instelling', 'new']);
	}

	gotoInstelling(instelling: RInstelling) {
		this.router.navigate(['instelling', instelling.id]);
	}

	getSelected(labels: Option<string | undefined>[], selectedLabel: string | undefined) {
		return find(labels, (option) => option.value === selectedLabel)!;
	}
}

<div class="header">
	<h2>{{ enviromentName }}</h2>
	<app-toggle-button (toggled)="uuidEnabled = $event" [checked]="uuidEnabled" class="toggle"> Toon UUID </app-toggle-button>
	<app-button class="right" (clickit)="openInstellingToevoegen()" icon="icon-add">Instelling toevoegen</app-button>
</div>
<div class="search-header">
	<app-instant-search-box (fire)="searchInput$.next($event)" placeholder="Instelling of bestuur zoeken"></app-instant-search-box>
	<div class="label-filter">
		<span class="label">Label</span>
		<dt-form-dropdown [opties]="labels" [selectedOptie]="getSelected(labels, selectedLabel())" (onOptieClicked)="selectedLabel.set($event)">
		</dt-form-dropdown>
	</div>
</div>
@if (instellingen$ | async; as instellingen) {
	<app-instellingen-list
		[instellingen]="instellingen"
		[uuidEnabled]="uuidEnabled"
		[warningIconEnabled]="true"
		[statusEnabled]="true"
		(instellingClick)="gotoInstelling($event)"
	>
	</app-instellingen-list>
}

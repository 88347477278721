import { Injectable } from '@angular/core';
import { BugsnagService } from '@cumlaude/bugsnag';
import Bugsnag from '@bugsnag/js';

@Injectable({
	providedIn: 'root',
})
export class AdminBugsnagService extends BugsnagService {
	setUser(userDisplayName: string) {
		if (!this.envConfig.bugsnagApiKey) return;

		Bugsnag.setUser(userDisplayName);
	}
}

import { Routes } from '@angular/router';
import { InstellingenPageComponent } from './pages/instellingen/instellingen-page.component';
import { NotFoundComponent, OAuthRouteComponent, authGuard } from '@cumlaude/shared-authentication';
import { InstellingPageComponent } from './pages/instelling/instelling-page.component';
import { InstellingToevoegenPageComponent } from './pages/instelling-beheren/instelling-toevoegen-page.component';
import { MagisterInstellingBewerkenPageComponent } from './pages/instelling-beheren/magister-instelling-bewerken-page.component';
import { BesturenPageComponent } from './pages/besturen/besturen-page.component';
import { BestuurPageComponent } from './pages/bestuur/bestuur-page.component';
import { BestuurToevoegenPageComponent } from './pages/bestuur-beheren/bestuur-toevoegen-page.component';
import { BestuurBewerkenPageComponent } from './pages/bestuur-beheren/bestuur-bewerken-page.component';

export const APP_ROUTES: Routes = [
	{
		path: 'oauth',
		children: [{ path: '', component: OAuthRouteComponent }],
	},
	{
		path: '',
		canActivate: [authGuard],
		canActivateChild: [authGuard],
		children: [
			{
				path: '',
				pathMatch: 'full',
				redirectTo: 'instelling',
			},
			{
				path: 'instelling',
				children: [
					{
						path: '',
						component: InstellingenPageComponent,
					},
					{
						path: 'new',
						component: InstellingToevoegenPageComponent,
					},
					{
						path: ':id',
						children: [
							{
								path: '',
								component: InstellingPageComponent,
							},
							{
								path: 'settings',
								component: MagisterInstellingBewerkenPageComponent,
							},
						],
					},
				],
			},
			{
				path: 'bestuur',
				children: [
					{
						path: '',
						component: BesturenPageComponent,
					},
					{
						path: 'new',
						component: BestuurToevoegenPageComponent,
					},
					{
						path: ':id',
						children: [
							{
								path: '',
								component: BestuurPageComponent,
							},
							{
								path: 'edit',
								component: BestuurBewerkenPageComponent,
							},
						],
					},
				],
			},
		],
	},
	{
		canActivate: [authGuard],
		canActivateChild: [authGuard],
		path: '**',
		component: NotFoundComponent,
	},
];

<app-button (clickit)="goBack()" buttonType="text" icon="icon-arrow-l">Terug naar besturen</app-button>

@if (bestuur$ | async; as bestuur) {
	<div class="header">
		<h2>{{ bestuur.naam }}</h2>
		<div class="right buttons">
			<app-button buttonType="secondary" (clickit)="deleteBestuur(bestuur)">Bestuur opheffen</app-button>
			<app-button buttonType="primary" (clickit)="editBestuur()">Bestuur bewerken</app-button>
		</div>
	</div>

	<div class="cards">
		<div class="row">
			<div class="column">
				<div class="card licenties">
					<div class="licenties-header">
						<h3>Licenties voor {{ bestuur.naam }}</h3>
					</div>
					<div class="licenties-content">
						<span>Bestuur</span>
						<input
							class="cell-value"
							type="number"
							min="0"
							max="999"
							[value]="bestuur.licenties"
							(blur)="changeLicenties(bestuur, $event)"
						/>
					</div>
				</div>
			</div>
			<div class="column">
				@if (instellingen$ | async; as instellingen) {
					<app-instellingen-list
						class="card"
						[toonBestuurLabel]="false"
						[bestuurLicentiesEnabled]="true"
						[totaalEnabled]="true"
						[instellingen]="instellingen"
						(instellingClick)="gotoInstelling(bestuur, $event)"
					>
					</app-instellingen-list>
				}
			</div>
		</div>
	</div>
}

<ng-template #confirmBestuurOpheffen let-data>
	<app-confirm-dialog caption="Bestuur opheffen">
		Je staat op het punt om het bestuur <b>{{ data.naam }}</b> op te heffen. Deze scholen zullen niet meer aan elkaar gekoppeld zijn voor de
		module Bestuur. Weet je het zeker?
	</app-confirm-dialog>
</ng-template>

export function getFriendlyEnvironmentName(environmentName: string) {
	switch (environmentName) {
		case 'Production':
			return 'Productie';
		case 'Acceptance':
			return 'Acceptatie';
		default:
			return environmentName.charAt(0).toUpperCase() + environmentName.slice(1);
	}
}

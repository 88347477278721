@if (instelling$ | async; as instelling) {
	<div class="header">
		<h2>{{ instelling.naam }}</h2>
		<app-instelling-labels [instelling]="instelling"></app-instelling-labels>
	</div>

	<div class="cards">
		<div class="row">
			<div class="column">
				<div class="card">
					<app-magister-instelling-settings [instelling]="instelling"></app-magister-instelling-settings>
				</div>
			</div>
		</div>
	</div>
}
